import { httpBatchLink, loggerLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import superjson from "superjson";

import type { AppRouter } from "@venudex/api";

const getBaseUrl = () => {
  if (typeof window !== "undefined") return ""; // browser should use relative url
  if (process.env.VERCEL_URL) {
    // SSR should use vercel url or production url
    return `https://${
      process.env.VERCEL_ENV === "production"
        ? process.env.PRODUCTION_URL
        : process.env.VERCEL_URL
    }`;
  }

  return `http://localhost:3000`; // dev SSR should use localhost
};

let apiKey: string;
export function setApiKey(newApiKey: string) {
  /**
   * You can also save the token to cookies, and initialize from
   * cookies above.
   */
  apiKey = newApiKey;
}

export const api = createTRPCNext<AppRouter>({
  config() {
    return {
      transformer: superjson,
      links: [
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === "development" ||
            (opts.direction === "down" && opts.result instanceof Error),
        }),
        httpBatchLink({
          url: `${getBaseUrl()}/api/trpc`,
          headers() {
            if (apiKey != null) {
              return {
                authorization: `Bearer ${apiKey}`,
              };
            } else {
              return {};
            }
          },
          maxURLLength: 8192,
        }),
      ],
    };
  },
  ssr: false,
});

export { type RouterInputs, type RouterOutputs } from "@venudex/api";
